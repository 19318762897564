/*keyframe for exiting*/
@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100%   {
    opacity: 0;
    transform: translateY(-20px);
  }
}


/*keyframe for entering*/
@-webkit-keyframes bounceIn {
  0%, 20%, 40%, 60%, 80%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }
  40% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  75% {
    -webkit-transform: scale3d(.95, .95, .95);
    transform: scale3d(.95, .95, .95);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes bounceIn {
  0%, 20%, 40%, 60%, 80%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }
  40% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  75% {
    -webkit-transform: scale3d(.95, .95, .95);
    transform: scale3d(.95, .95, .95);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.maximus-dialog-paper {
  outline: none;
  min-width: 290px;
  overflow: hidden;
  width: 100%;
  border-radius: 10px !important;
}

.maximus-dialog-paper.sm {
  max-width: 350px;
}

.maximus-dialog-paper.md {
  max-width: 600px;
}

.maximus-dialog-paper.lg {
  max-width: 800px;
}

.maximus-dialog-paper.xlg {
  max-width: 1200px;
}

.maximus-dialog-paper.entering {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
  -webkit-animation-duration: 0.35s;
  animation-duration: 0.35s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.maximus-dialog-paper.exiting {
  animation:         fadeOut 0.25s;
}

.maximus-dialog-title {
  position: relative;
  padding: 2.5rem 1rem 0;
  font-size: 2rem;
}

.maximus-dialog-close-icon {
  position: absolute !important;
  top: 10px;
  right: 10px;
}

.maximus-dialog-content {
  padding: 2rem 1rem;
  max-height: calc(100vh - 100px);
  overflow-x: hidden;
  overflow-y: auto;
}

.maximus-dialog-checkbox {
  margin-bottom: -15px;
  padding-top: 5px;
}

.maximus-dialog-footer.left {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.5rem 1rem 2rem  1rem;
}

.maximus-dialog-footer.right {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0.5rem 1rem 2rem  1rem;
}

.maximus-dialog-footer.center {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem 2rem  1rem;
}
