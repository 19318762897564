/*.App {*/
/*text-align: center;*/
/*}*/

/*.App-logo {*/
/*animation: App-logo-spin infinite 20s linear;*/
/*height: 80px;*/
/*}*/

/*.App-header {*/
/*background-color: #222;*/
/*height: 150px;*/
/*padding: 20px;*/
/*color: white;*/
/*}*/

/*.App-intro {*/
/*font-size: large;*/
/*}*/

/*@keyframes App-logo-spin {*/
/*from { transform: rotate(0deg); }*/
/*to { transform: rotate(360deg); }*/
/*}*/

.drag-handle {
    display: none;
}


body {
    font-family: Roboto, sans-serif;
}

.adminFlag {
    text-align: center;
    background-color: #F44336;
    font-weight: bold;
    color: white;
    padding: 1rem;
    margin: .5rem 0 .5rem 0;
}

td {
    font-size: 16px !important;
}

th {
    padding-left: 8px !important;
}

table {
    z-index: 500 !important;
}

img {
    max-width: 100%;
    max-height: 100%;
    margin: .25rem;
}

small {
    font-size: 0.75rem;
}

.datePickerPopover {
    overflow-x: auto !important;
}

.spaced-input {
    width: 90% !important;
}

#networkButtons > span {
    height: 100%;
}

.spaced-text-input {
    margin-right: 1rem !important;
}

.captionText {
    color: gray !important;
    font-weight: 300 !important;
    font-size: .9rem!important;
}

.icon-button {
    height: 35px !important;
    width: 35px !important;
    padding: 0 !important;
    margin: 1rem !important;
}

.menuItem {
    padding-right: 0 !important;
    color: black,
}

#loginPage {
    height: 100vh;
    display: flex;
}

.helpLink {
    font-size: .8rem;
}

th>div>div {
    margin-left: 0!important;
}

.Select.has-value.is-clearable  {
    height: 100%;
}

.doka--root .doka--button-action-confirm {
    padding: 6px 16px;
    color: white;
    background-color: green !important;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    cursor: pointer;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.doka--root .doka--button-action-confirm:hover {
    background-color: rgba(0,128,0,.8) !important;
}
